import PropTypes from 'prop-types'
import React from 'react'
import { Container } from 'react-bootstrap'
import PreviewCompatibleHTML from '../components/PreviewCompatibleHTML'
import Section from '../components/Section'

const PrivacyPolicyPageTemplate = ({ content }) => {
  return (
    <>
      <Section
        style={{
          width: '100vw',
          background: 'var(--gradient-purple)',
          color: 'white',
        }}>
        <Container>
          <h1>Privacy Policy</h1>
        </Container>
      </Section>
      <Container>
        <Section>
          <PreviewCompatibleHTML content={content} />
        </Section>
      </Container>
    </>
  )
}

PrivacyPolicyPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
}

export default PrivacyPolicyPageTemplate
