import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import PrivacyPolicyPageTemplate from './privacy-policy-page-template'

const PrivacyPolicyPage = ({ data }) => {
  const { markdownRemark: privacyPolicy } = data

  return (
    <Layout>
      <Seo title='Moolah - Privacy Policy' />
      <PrivacyPolicyPageTemplate content={privacyPolicy.html} />
    </Layout>
  )
}

export default PrivacyPolicyPage

export const pageQuery = graphql`
  query PrivacyPolicyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "privacy-policy-page" } }) {
      html
    }
  }
`
